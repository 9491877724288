import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function TablelRates({
  rate,
  handleEdit,
  handleSold,
  handleDelete,
  handleCheckboxChange,
  checkedItems,
}) {
  return (
    <tr key={rate.id}>
      {/* <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            // name="chk_child"
            defaultValue={question.id}
            onChange={handleCheckboxChange}
            // checked={checkedItems.includes(question.id)}
          />
        </div>
      </th> */}

      <td className="customer_name">{rate.roomtype}</td>
      <td className="customer_name">{rate.roomfloor}</td>
      <td className="customer_name">{rate.syp}</td>
      <td className="customer_name">{rate.usd}</td>

      <td>
        <div className="d-flex gap-2">
          <div className="edit">
            <button
              className="btn  btn-md btn-success edit-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              onClick={() => handleEdit(rate)}
            >
              <i className="ri-edit-line align-bottom "></i>
            </button>
          </div>

          <div className="remove">
            <button
              className="btn  btn-danger btn-md remove-item-btn"
              onClick={() => handleDelete(rate.id)}
            >
              <i class="ri-delete-bin-6-line  align-bottom"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}
