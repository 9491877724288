import {
  ADD_EVENT_FAIL,
  ADD_EVENT_SUCCESS,
  GET_EVENTS_FAIL,
} from "../calendar/actionTypes";
import {
  ADD_RATES_SUCCESS,
  DELETE_RATES_SUCCESS,
  GET_RATES_FAIL,
  GET_RATES_SUCCESS,
  UPDATE_RATES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  Rates: [],

  error: {},
};

const Rates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RATES_SUCCESS:
      return {
        ...state,
        Rates: action.payload.apples,
      };

    case GET_RATES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_RATES_SUCCESS:
      return {
        ...state,
        Rates: [...state.Rates, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_RATES_SUCCESS:
      return {
        ...state,
        Rates: state.Rates.map((id) =>
          id.id === action.payload.id ? { id, ...action.payload } : id
        ),
        isEventUpdated: true,
      };

    // case UPDATE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     isEventUpdated: false,
    //   };

    case DELETE_RATES_SUCCESS:
      return {
        ...state,
        Rates: state.Rates.filter((id) => id.id !== action.payload),
      };

    // case DELETE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default Rates;
