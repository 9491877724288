import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOAD_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: {},
  isUserLogout: true,
  token: localStorage.getItem("token"),
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      state = {
        ...state,
        loading: false,
        user: {
          ...action.payload.user,
          questions: action.payload.questions,
          locations: action.payload.locations,
        },
        token: action.payload.token,
        isUserLogout: false,
      };
      break;
    case LOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {
          ...action.payload.user,
          questions: action.payload.questions,
          locations: action.payload.locations,
        },
        isUserLogout: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, loading: true };
      break;
    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem("token");
      state = {
        ...state,
        isUserLogout: true,
        loading: false,
        user: {},
        token: null,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        // isUserLogout: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
