import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function TableItem({
  question,
  handleEdit,
  handleSold,
  handleDelete,
  handleCheckboxChange,
  checkedItems,
}) {
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const copied = () => {
    toast.success("Text Copied", {
      position: "bottom-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <tr key={question.id}>
      {/* <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            // name="chk_child"
            defaultValue={question.id}
            onChange={handleCheckboxChange}
            // checked={checkedItems.includes(question.id)}
          />
        </div>
      </th> */}
     
      <td className="customer_name">{question.question}</td>
      
      <td>
        <div className="d-flex gap-2">
          <div className="edit">
            <button
              className="btn  btn-md btn-success edit-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              onClick={() => handleEdit(question)}
            >
              <i className="ri-edit-line align-bottom "></i>
            </button>
          </div>
         
         
         
          <div className="remove">
            <button
              className="btn  btn-danger btn-md remove-item-btn"
              onClick={() => handleDelete(question.id)}
            >
              <i class="ri-delete-bin-6-line  align-bottom"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}
