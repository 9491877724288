import React from "react";
import UsePanel from "./UserPanel";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  document.title = "Dashboard | Rosa";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Rosa" breadcrumbItem="Dashboard" />
          {/* User Panel Charts */}
          <UsePanel />

          {/* <Row> */}
          {/* Overview Chart */}
          {/* <OverView /> */}
          {/* Social Source Chart */}
          {/* <SocialSource /> */}
          {/* </Row> */}

          {/* <Row> */}
          {/* Order Stats */}
          {/* <OrderStatus /> */}
          {/* Notifications */}
          {/* <Notifications /> */}
          {/* Revenue by Location Vector Map */}
          {/* <RevenueByLocation /> */}
          {/* </Row> */}

          {/* Latest Transaction Table */}
          {/* <LatestTransation /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
