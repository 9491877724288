
import {
  ADD_EVENT_FAIL,
  ADD_EVENT_SUCCESS,
  GET_EVENTS_FAIL,
} from "../calendar/actionTypes";
import {
  ADD_LOCATIONS_SUCCESS,
  DELETE_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  Locations: [],
  
  error: {},
};

const Locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        Locations: action.payload.apples
        
      };

    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_LOCATIONS_SUCCESS:
      return {
        ...state,
        Locations: [...state.Locations, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        Locations: state.Locations.map((id) =>
          id.id === action.payload.id ? { id, ...action.payload } : id
        ),
        isEventUpdated: true,
      };

    // case UPDATE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     isEventUpdated: false,
    //   };

    case DELETE_LOCATIONS_SUCCESS:
      return {
        ...state,
        Locations: state.Locations.filter((id) => id.id !== action.payload),
      };

    // case DELETE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default Locations;
