import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_RATES_FAIL,
  ADD_RATES_SUCCESS,
  DELETE_RATES_SUCCESS,
  GET_RATES,
  GET_RATES_SUCCESS,
  UPDATE_RATES_SUCCESS,
} from "./actionTypes";

export const loadRates = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/rates/get");

  toast.promise(req, {
    pending: "Getting rates...",
    success: {
      render({ data }) {
        dispatch({
          payload: { apples: data.data.data, exports: data.data.exports },
          type: GET_RATES_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting rates";
      },
    },
  });
};

export const addRates = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/rates/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_RATES_SUCCESS,
        });
        return "rate added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updateRates = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/rates/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_RATES_SUCCESS,
        });
        return "rate updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your rates";
      },
    },
  });
};

export const deleteRates = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/rates/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_RATES_SUCCESS,
        });
        return "rate deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your rates";
      },
    },
  });
};
