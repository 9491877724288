import React from "react";
import ReactApexChart from "react-apexcharts";

const RadialChart1 = ({ pres }) => {
  const series = [pres];
  const radialoptions = {
    chart: {
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#ba8f2e"],
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          margin: 0,
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 5,
            show: true,
          },
        },
      },
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={radialoptions}
        series={series}
        type="radialBar"
        height="150"
        width="150"
      />
    </React.Fragment>
  );
};

export default RadialChart1;
