import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { addQ, updateQ } from "../../../store/qanda/actions";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  question: yup.string().max(100).required(),
  answer: yup.string().max(100).required(),
  questionar: yup.string().max(100).required(),
  answerar: yup.string().max(100).required(),
});

export default function AddNew({
  modal_center,
  tog_center,
  setmodal_center,
  data,
}) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      question: data.question,
      answer: data.answer,
      questionar: data.questionar,
      answerar: data.answerar,
    },
  });

  const onSubmit = (formData) => {
    console.log("gggg");
    if (Object.keys(data).length !== 0) {
      // let data = data.append("image", files);
      dispatch(updateQ({ ...formData, id: data.id }));
    } else {
      // let data = data.append("image", files);
      dispatch(addQ({ ...formData }));
    }
    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_center(false);
    }
  };

  return (
    <Modal
      isOpen={modal_center}
      toggle={() => {
        tog_center();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-add-circle-line" style={{ paddingRight: 5 }}></i>{" "}
          {Object.keys(data).length !== 0 ? "Update" : "Add"} Add New Question
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.question ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("question")}
                />
                <span class="label">Question</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.questionar ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("questionar")}
                />
                <span class="label">Arabic Question</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.answer ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("answer")}
                />
                <span class="label">Answer</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.answerar ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("answerar")}
                />
                <span class="label">Arabic Answer</span>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => {
                setmodal_center(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              //   onClick={() => {
              //     setmodal_center(false);
              //   }}
              className="btn btn-lg btn-primary"
            >
              {Object.keys(data).length !== 0 ? "Update" : "Add New"}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
